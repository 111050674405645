import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout>
      <SEO title="About Us" />

      <section
        className="pt-3"
        style={{ /*marginTop: 176,*/ backgroundColor: "#fff" }}
      >
        <div className="container mx-auto">
          <h1 className="display-8 text-center text-uppercase pb-3">
            About Us
          </h1>
        </div>
        <div className="container">
          <p>
            We have welcomed dogs of all sizes and breeds into our homes for
            over 30 years. Having a dog as part of our family has been passed on
            from generation to generation. Our whole family adores dogs. We
            currently have two goldendoodles who have brought us immense
            happiness. They are not just dogs. They are not just our pets. They
            are our family! Being part of our family means we celebrate their
            birthdays, we take care of all their needs, and we never ever
            abandon our family. We include our dogs in family picutes because
            they are our family. We travel with them. Our home is where our
            family is, therefore our family is where our dogs are at. We have
            created wonderful memories with our dogs that we will cherish for
            years to come. They been there through our worst days and our best
            days. At the end of the day they always welcome us with a wagging
            tail and so much joy. They are truly our family and best friends.
          </p>
          <p>
            So why puppies? Our friends and family have seen how we have
            integrated our dogs into our family. They have seen the happiness
            our dogs have brought us. They have seen how worried we get when
            something happens to our dogs even if it's as simple as a thorn in
            their paw. They have seen how our dogs have completed our lives. And
            guess what? Even if they never considered welcoming a dog into their
            family before, well now they certaintly have!
          </p>
          <p>
            Our mission is to unite families with their new family member so
            they can experience the same immense happiness our dogs have brought
            us! We want to share the joy with families!
          </p>
          <p>
            Here at Sunny Doodles AZ, we take great pride in the outstanding
            high quality care we provide our puppies. Our puppies are our
            family. When it comes to family we do anything for family. With that
            being said, we attend to the puppies around the clock whether it's
            night or day. Our puppies are never left alone unsupervised. They
            are raised in a family home where they are socialized since birth.
            Not only do we attend to their needs but we find absolute joy in
            having them here with us until they find their new home! We do
            photoshoots with all our puppies, we play on a daily basis with all
            our puppies, we give them a temporary name, and have a party! What
            kind of party? Well the best there is! A toy party! A toy party is
            where we bombard the puppies with so many toys at once that they
            don't even know which ones to play with!
          </p>
          <p>
            When our puppies leave us we feel the silence in our home. We truly
            fall in love with each and every one of the puppies. The puppies
            become part of the family. We learn their personalities, we name
            them, and become attached to them! Please don't mind if we get sad
            when the puppies leave to their new home! We absolutely adore
            hearing updates about all our puppies! We want to stay updated on
            our puppies journeys and all the big adventures they encounter! We
            find joy in knowing that our puppies will go to families who will
            adore them just as much as we have!
          </p>
          <p>Our values: Family, high quality care, best friends for life</p>
        </div>
      </section>
    </Layout>
  )
}

export default About
